// fetch configure option images
export const getSelectedConfigureOptionImage = state => (state.configImageDetailsRepository && state.configImageDetailsRepository.imageUrls) || '';

// fetch active Tab details
export const getActiveTabDetails = state => (state.tabRepository && state.tabRepository.activeTab) || {};

export const getSelectedFeatureClasses = state => (state.configurationImageRepository && state.configurationImageRepository.featureClasses) || {};
//export const getToggleConfigPanelState = state => (state.configurationImageRepository && state.configurationImageRepository.isPanelVisible) || {};

//fetch guided search selections
export const getGuidedRoomsCount = state => (state.guidedQuizRepository && state.guidedQuizRepository.totalRooms) || 0;
export const getGuidedImageSelected = state => (state.guidedQuizRepository && state.guidedQuizRepository.roomImages) || '';
export const getGuidedRangeSelected = state => (state.guidedQuizRepository && state.guidedQuizRepository.ranges) || '';
export const getSearchGuidedResults = state => (state.guidedQuizRepository && state.guidedQuizRepository.searchResults) || {};
export const getCurrentStep = state => (state.guidedQuizRepository && state.guidedQuizRepository.step) || 1;

//fetch guided results
export const getGuidedResultsProduct = state => (state.guidedQuizRepository && state.guidedQuizRepository.searchResults && state.guidedQuizRepository.searchResults) || [];
export const getGuidedResultsProductIndex = state => (state.guidedResultsRepository && state.guidedResultsRepository.modelIndex) || 0;
export const getGuidedResultsProductPrevUrl = state => (state.guidedResultsRepository && state.guidedResultsRepository.prevUrl) || '';
export const getGuidedResultsProductClear = state => (state.guidedResultsRepository && state.guidedResultsRepository.isReset) || false;
export const getGuidedResultsCartName = state => (state.guidedResultsRepository && state.guidedResultsRepository.cartName) || '';

export const getGuidedClear = state => (state.guidedRepository && state.guidedRepository.isReset) || false;
export const getGuidedCartName = state => (state.guidedRepository && state.guidedRepository.cartName) || '';
export const getNonGuidedClear = state => (state.nonGuidedRepository && state.nonGuidedRepository.isReset) || false;
export const getNonGuidedCartName = state => (state.nonGuidedRepository && state.nonGuidedRepository.cartName) || '';

//fetch selected products for review page
export const getReviewProducts = state => (state.reviewProductsRepository && state.reviewProductsRepository.updatedConfigData) || {};
export const getReviewProductID = state => (state.reviewProductsRepository && state.reviewProductsRepository.productID) || '';
export const getReviewEditSection = state => (state.reviewProductsRepository && state.reviewProductsRepository.editSection) || '';
export const getConfigID = state => (state.reviewProductsRepository && state.reviewProductsRepository.configID) || '';
export const getReviewAndSubmitQuoteClicked = state => (state.reviewProductsRepository && state.reviewProductsRepository.reviewAndSubmitQuoteClicked) || '';

//Guided selling share config Id
export const getGuidedSellingModels = state => (state.guidedSellingModalsConfigRepository && state.guidedSellingModalsConfigRepository.modalConfigIds) || {};
//Update Config Repo
export const getUpdateConfigRepo = state => (state.UpdateConfigRepository || {});

// logOutRepository    
export const getLogoutAlertShow = state => (state.logOutRepository &&  state.logOutRepository.setLogoutAlertShow || false);
export const getSaveConfigurationShow = state => (state.logOutRepository &&  state.logOutRepository.setSaveConfigurationShow || false);
export const getSaveConfigurationCount = state => (state.logOutRepository &&  state.logOutRepository.saveConfigurationCount || 0);
export const getIsConfigMobileView = state => (state.arraySetRepository &&  state.arraySetRepository.isConfigMobileView || 'true');
export const getSetNavigationToggleInMob = state => (state.arraySetRepository &&  state.arraySetRepository.isNavBarOpen || 'false');
export const getlinkToRedirect = state => (state.logOutRepository && state.logOutRepository.setlinkToRedirect || '');
export const getIsConfigurationReplicated = state => (state.logOutRepository && state.logOutRepository.isConfigurationReplicated || false)
export const getMaintenanceLinkStatus = state => (state.logOutRepository && state.logOutRepository.maintenanceLink || false);
export const getShowProfilePageForNewUser = state => (state.logOutRepository && state.logOutRepository.showProfilePageForNewUser || false);

//configurationOptionSelectionRepository
export const getConfigurationOptionSelectionRepository = state => (state.configurationOptionSelectionRepository || {});
export const getToggleConfigPanelState = state => (state.configurationOptionSelectionRepository && state.configurationOptionSelectionRepository.isPanelVisible) || {};
export const getTabOpenInMobile = state => (state.configurationOptionSelectionRepository && state.configurationOptionSelectionRepository.isTabOpenInMobile) || false;

//mediaGalleryRepository
export const getSelectedMediaGalleryImgDetails = state => (state.mediaGalleryRepository && state.mediaGalleryRepository.selectedImageDetails) || {};
export const getSelectedMediaGalleryImgIndex = state => (state.mediaGalleryRepository && state.mediaGalleryRepository.selectedIndex) || 0;

//quoteDetailsRepository
export const callQuoteDetailsAfterLogin =  state => (state.quoteDetailsRepository && state.quoteDetailsRepository.orderId) || '';

//product Listing Filter Repository
export const getIsUpdateFilterCalled = state => (state.productListingFilterRepository &&  state.productListingFilterRepository.isUpdateCalled || false);
export const getSelectedSortType = state => (state.productListingFilterRepository &&  state.productListingFilterRepository.selectedSortType || []);
export const getSelectedAreaFilter = state => (state.productListingFilterRepository &&  state.productListingFilterRepository.selectedAreaFilter || []);
export const getSelectedPriceFilter = state => (state.productListingFilterRepository &&  state.productListingFilterRepository.selectedPriceFilter || []);
export const getSelectedCategoryFilter = state => (state.productListingFilterRepository &&  state.productListingFilterRepository.selectedCategoryFilter || "");
export const getLoader = state => (state.productListingFilterRepository &&  state.productListingFilterRepository.isLoader || false);
export const getNoOfRecordsLoading = state => (state.productListingFilterRepository &&  state.productListingFilterRepository.noOfRecordsLoading || 0);
export const getSelectedBedroomFilter = state => (state.productListingFilterRepository &&  state.productListingFilterRepository.selectedBedRoomFilter || '');
export const getSelectedBathroomFilter = state => (state.productListingFilterRepository &&  state.productListingFilterRepository.selectedBathRoomFilter || '');

//UpdateProfileNotificationRepository
export const getProfileNotificationData = state => (state.UpdateProfileNotificationRepository &&  state.UpdateProfileNotificationRepository.profileNotificationData || {});

//ConfigurationTutorialRepository
export const getConfigurationTutorialData = state => (state.ConfigurationTutorialRepository &&  state.ConfigurationTutorialRepository.configurationTutorialData || {});
export const getConfigurationTutorialStep = state => (state.ConfigurationTutorialRepository &&  state.ConfigurationTutorialRepository.configurationTutorialStep || '');
export const getDecorImagesLoaded = state => (state.ConfigurationTutorialRepository &&  state.ConfigurationTutorialRepository.decorImagesLoaded || false);

